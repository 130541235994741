import { general } from "config/config"
import * as React from "react"

import { styled } from "@mui/material/styles"

import { toLocalDate } from "@nhi/utils"

interface IRevisedOrNewProps {
    date?: Date
    content: string
}
export const RevisedOrNewBadge: React.FC<IRevisedOrNewProps> = ({
    date,
    content
}) => {
    if (general.suppressNewAndRevisedBadges || !date) {
        return null
    }

    const now = new Date().getTime()
    const days = (now - new Date(date).getTime()) / (24 * 60 * 60 * 1000)

    if (days > 180) {
        return null
    }

    return (
        <Badge
            content={content}
            title={toLocalDate(date, process.env.NEXT_PUBLIC_LOCALE)}
        />
    )
}

interface IBadgeProps {
    title: string
    content: string | JSX.Element
}
export default function Badge({ title, content }: IBadgeProps) {
    return <StyledBadge title={title}>{content}</StyledBadge>
}

const StyledBadge = styled("span")(({ theme }) => ({
    backgroundColor: theme.palette.badge,
    borderRadius: "2px",
    marginLeft: theme.spacing(1),
    padding: "4px 6px",
    fontSize: 12,
    lineHeight: "normal"
}))
