import PortableText from "components/PortableText/PortableText"
import { paywallDelay } from "config/config"
import useGetAccessInfo from "hooks/useGetAccessInfo"
import { useTimeout } from "hooks/useTimeout"
import * as React from "react"

import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { useGetPaywall } from "../../../data/contentData/hooks/config.hooks"
import { IPage, PaywallType } from "data/contentData/api/content"

export default function Paywall({ page }: { page: IPage }) {
    const [showPaywall, setShowPaywall] = React.useState(false)
    const { paywallType } = useGetAccessInfo(page)

    useTimeout(() => {
        setShowPaywall(true)
    }, paywallDelay)

    if (paywallType === "none" || !showPaywall) {
        return null
    }

    return (
        <StyledContainer>
            <StyledCard variant="outlined">
                <Content type={paywallType} />
            </StyledCard>
        </StyledContainer>
    )
}

const StyledContainer = styled("div")(() => ({
    background: "#ffffffd4",
    height: "100%",
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    backdropFilter: "blur(4px)"
}))

const StyledCard = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "sticky",
    top:
        theme.constants.height.header + theme.constants.height.breadcrumbs + 16,
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(4, 12)
    }
}))

function Content({ type = "not-logged-in" }: { type: PaywallType }) {
    const { data: paywall } = useGetPaywall(type)

    if (!paywall) {
        return null
    }

    return (
        <div id="gtm-paywall">
            <Typography variant="h2">{paywall.title}</Typography>
            <PortableText value={paywall.blockContent} />
        </div>
    )
}
