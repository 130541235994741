import { post } from "@nhi/utils"

export async function refreshPage({ path }: { path: string }) {
    try {
        const response = await post<Response>(
            "/api/refresh-page",
            JSON.stringify(path)
        )

        if (
            response === undefined ||
            (response instanceof Response && response.status === 204)
        ) {
            return undefined
        }
        return response as Response
    } catch (error) {
        throw new Error(
            `Error when trying to refresh page: ${JSON.stringify(error)}`
        )
    }
}
