import Collapsible from "components/Collapsible"
import { t } from "locales"
import React from "react"

import Typography from "@mui/material/Typography"

import { IAuthor, IPage } from "data/contentData/api/content"

interface ArticleAuthorsSectionProps {
    id: string
    page: IPage
}

export default function ArticleAuthorsSection({
    id,
    page
}: ArticleAuthorsSectionProps) {
    if (!page.authors) return null

    const current = page.authors.filter(author => author.current)
    const former = page.authors.filter(author => !author.current)

    const title = t["article"]["authors-title"]

    return (
        <Collapsible
            id={id}
            level={3}
            title={title}>
            <ul>
                {current.map(author => (
                    <Author
                        key={author.name}
                        {...author}
                    />
                ))}
            </ul>

            {former.length ? (
                <>
                    <Typography variant="h4">
                        {t["article"]["former-authors"]}
                    </Typography>
                    <ul>
                        {former.map(author =>
                            author.name ? (
                                <Author
                                    key={author.name}
                                    {...author}
                                />
                            ) : null
                        )}
                    </ul>
                </>
            ) : null}

            {page.includeAuthorText && <InternationalCooperation />}
        </Collapsible>
    )
}

export function Author(props: IAuthor) {
    const { name, title, organization } = props

    return (
        <li>
            <span>{name}</span>
            {title ? (
                <Typography
                    component="span"
                    variant="body2">
                    , {title}
                </Typography>
            ) : null}
            {organization ? (
                <Typography
                    component="span"
                    variant="body2">
                    , {organization}
                </Typography>
            ) : null}
        </li>
    )
}

function InternationalCooperation() {
    return (
        <>
            <Typography variant="h4">
                {t["article"]["international-cooperation-title"]}
            </Typography>
            <ul>
                <li>{t["article"]["international-cooperation-content"]}</li>
            </ul>
        </>
    )
}
