import Badge, { RevisedOrNewBadge } from "components/Badge"
import Icon from "components/Icon"
import useUser from "hooks/useUser"
import { t } from "locales"
import * as React from "react"

import ListItem from "@mui/material/ListItem"

import { isOutdated, isPublished } from "@nhi/utils"
import { IPage } from "data/contentData/api/content"
import { hasCMSAccess } from "lib/auth"

import SitelistLink from "./SitelistLink"

export default function SitelistItem(page: IPage) {
    const {
        labels,
        url,
        shortcut,
        name,
        revised,
        published,
        unpublished,
        revisionInterval,
        hasImportantUpdateUntil
    } = page
    const { user } = useUser()

    const isEditor = hasCMSAccess(user?.roles)
    const outdated = isEditor
        ? isOutdated(revisionInterval, published, revised)
        : false

    if (!isPublished(published, unpublished)) {
        return null
    }

    return (
        <ListItem
            key={url}
            disableGutters>
            <SitelistLink
                name={name}
                href={shortcut ?? url}
                isOutdated={outdated && isEditor}
            />

            <RevisedOrNewBadge
                content={t.search.revised}
                date={revised}
            />
            <RevisedOrNewBadge
                content={t.search.new}
                date={published}
            />

            {labels?.map((label: string) => (
                <Badge
                    key={label}
                    content={label}
                    title={label}
                />
            ))}
            {hasImportantUpdateUntil && (
                <Badge
                    content={
                        <Icon
                            name="important-update"
                            fontSize="inherit"
                        />
                    }
                    title={t["important-updates"]["important-update"]}
                />
            )}
        </ListItem>
    )
}
