import React, { useEffect } from "react"

import useMousePressed from "@nhi/hooks/useMousePressed"
import useTextSelection from "@nhi/hooks/useTextSelection"
import { useGetDictionaryTerm } from "data/contentData/hooks/config.hooks"

const defaultReturnState = { definition: undefined, rect: undefined }
/*
 * Takes text selection and decides if it should search
 * @returns
 */
export default function useDictionarySelectionSearch() {
    const { clientRect, textContent } = useTextSelection()
    const { isPressed } = useMousePressed()
    const [enabled, setEnabled] = React.useState(false)

    // Check if we should search for word or not
    useEffect(() => {
        const shouldSearch = textContent
            ? textContent.split(" ").length < 4 && !isPressed
            : false

        setEnabled(false)

        const intervalId = setTimeout(() => {
            setEnabled(shouldSearch)
        }, 500)

        return () => {
            clearTimeout(intervalId)
        }
    }, [isPressed, setEnabled, textContent])

    // Remove enable on escape
    useEffect(() => {
        const resetEnable = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setEnabled(false)
            }
        }

        document.addEventListener("keydown", resetEnable)
        return () => {
            document.removeEventListener("keydown", resetEnable)
        }
    }, [setEnabled])

    const { data: definition } = useGetDictionaryTerm(textContent?.trim(), {
        enabled: enabled,
        retry: false
    })

    // If no selection / not enabled / no definition send back object with undefined values
    if (!textContent || !enabled || !definition?.words) {
        return defaultReturnState
    }
    return {
        definition,
        rect: clientRect
    }
}
