import Collapsible from "components/Collapsible"
import { GalleryProvider } from "components/Gallery"
import Main from "components/Layout/Main"
import Meta from "components/Meta"
import PortableText from "components/PortableText/PortableText"
import { SkipNavContent } from "components/SkipNav"
import useGetAccessInfo from "hooks/useGetAccessInfo"
import useUser from "hooks/useUser"
import { useInteractions } from "modules/interactions/InteractionsContext"
import { noPrintSectionTypes } from "pages/[...page]"
import * as React from "react"

import Grid from "@mui/material/Grid"

import useEventualScroll from "@nhi/hooks/useEventualScroll"
import { IPage, ISection } from "data/contentData/api/content"
import getContentIds from "lib/get-sources-ids"

import ArticleAuthorsSection from "../components/ArticleAuthorsSection"
import ArticleLeftAside from "../components/ArticleLeftAside"
import ArticleReferencesSection from "../components/ArticleReferencesSection"
import CollapsibleContent from "../components/CollapsibleContent"
import Paywall from "../components/Paywall"
import RegionalContent from "../components/RegionalContent"
import SummaryContent from "../components/SummaryContent"
import DrawerTools from "../components/Tools/DrawerTools"
import Tools from "../components/Tools/Tools"
import ArticleHeader from "../components/article-header/PageHeader"

interface IProps {
    page: IPage
    isPublic?: boolean
}

export default function ProfessionalPageTemplate({ page, isPublic }: IProps) {
    const { showTools, closeTools } = useInteractions()
    const { regionalContentId } = getContentIds(page)

    return (
        <Main
            leftAside={
                page.template?.hasNavigation ? (
                    <ArticleLeftAside page={page} />
                ) : null
            }
            rightAside={
                page.template?.hasTools ? (
                    <Tools
                        tools={page.linkLists}
                        regionalContentId={regionalContentId}
                    />
                ) : null
            }>
            <DrawerTools
                onClose={closeTools}
                open={showTools}
                lists={page.linkLists}
                regionalContentId={regionalContentId}
            />

            <Grid
                container
                component="article">
                <Meta
                    noIndex={page.noIndex}
                    authors={page.authors}
                    dateModified={page.updated}
                    datePublished={page.published}
                    isPublic={isPublic}
                    title={page.title}
                    metadata={page.metadata}
                    type="article"
                    url={page.url}
                />
                <Grid
                    item
                    xs
                    id="article-content">
                    <SkipNavContent />
                    <ArticleHeader page={page} />
                    <GalleryProvider gallery={page.gallery}>
                        <ArticleContent page={page} />
                    </GalleryProvider>
                </Grid>
            </Grid>
        </Main>
    )
}

function ArticleContent({ page }: { page: IPage }) {
    useEventualScroll()

    const { paywallType } = useGetAccessInfo(page)
    if (paywallType === "none") {
        return (
            <>
                {page.sections?.map(section => (
                    <Section
                        key={section.url}
                        section={section}
                    />
                ))}
                <SourcesSection page={page} />
            </>
        )
    }

    const previewSections = page.sections?.filter(s => s.isPreview)
    const premiumSections = page.sections?.filter(s => !s.isPreview)
    return (
        <div className="paywall">
            {previewSections?.map(section => (
                <Section
                    key={section.url}
                    section={section}
                    isPreview
                />
            ))}

            <div style={{ position: "relative" }}>
                <Paywall page={page} />

                {premiumSections?.map(section => (
                    <Section
                        key={section.url}
                        section={section}
                    />
                ))}

                <SourcesSection page={page} />
            </div>
        </div>
    )
}

function Section({
    section,
    isPreview = false
}: {
    section: ISection
    isPreview?: boolean
}) {
    const { authenticated } = useUser()

    switch (section.type) {
        case "summary": {
            return (
                <SummaryContent
                    key={section.url}
                    id={section.url}
                    content={section.blockContent}
                />
            )
        }

        case "root": {
            return (
                <PortableText
                    key={section.url ?? "root"}
                    value={section.blockContent}
                    wrapImgWithGalleryLink
                />
            )
        }

        case "regional":
            if (!authenticated) return null
            return (
                <RegionalContent
                    key={section.url}
                    section={section}
                />
            )
        default:
            const _section = {
                ...section,
                collapsed: isPreview ? false : section.collapsed
            }
            return (
                <CollapsibleContent
                    key={_section.name}
                    section={_section}
                    noPrint={noPrintSectionTypes.includes(_section.type)}
                />
            )
    }
}

function SourcesSection({ page }: { page: IPage }) {
    const { authorsContentId, referencesContentId } = getContentIds(page)

    // If page.sources does NOT exist => references & authors does NOT exist aswell!
    if (!page.sources) {
        return null
    }

    const { blockContent, url, name, heading, sections, type, collapsed } =
        page.sources

    return (
        <Collapsible
            id={url}
            level={2}
            title={name}
            heading={heading}
            collapsed={collapsed}
            noPrint={noPrintSectionTypes.includes(type)}
            isTopPanel>
            {blockContent ? (
                <PortableText
                    value={blockContent}
                    wrapImgWithGalleryLink
                />
            ) : null}
            {sections?.map(subSection => {
                const subSectionBlockContent = subSection.blockContent ? (
                    <PortableText
                        key={subSection.url}
                        value={subSection.blockContent}
                        wrapImgWithGalleryLink
                    />
                ) : null
                if (subSection.name) {
                    return (
                        <Collapsible
                            key={subSection.name}
                            id={subSection.url}
                            level={3}
                            title={subSection.name}
                            heading={subSection.heading}
                            collapsed={subSection.collapsed}>
                            {subSectionBlockContent}
                        </Collapsible>
                    )
                }
                return subSectionBlockContent
            }) ?? null}
            {page.references && (
                <ArticleReferencesSection
                    references={page.references}
                    template="Professional"
                    id={referencesContentId}
                />
            )}
            {page.authors && (
                <ArticleAuthorsSection
                    page={page}
                    id={authorsContentId}
                />
            )}
        </Collapsible>
    )
}
