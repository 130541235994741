import Icon, { IconType } from "components/Icon"
import Link from "components/Link"
import { t } from "locales"
import * as React from "react"

import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

import { ILinkList, ILinkListLink } from "data/contentData/api/content"

interface ToolProps {
    icon?: IconType
    action?: React.ReactElement
    title?: string | React.ReactElement
    children: React.ReactNode
}

function Tool({ icon, title, action, children }: ToolProps) {
    return (
        <StyledToolRoot container>
            <Grid
                container
                item
                alignItems="center">
                {icon ? <Icon name={icon} /> : null}
                <Typography
                    sx={{ fontWeight: 700, ml: 1 }}
                    component="span"
                    variant="body2">
                    {title ?? t.tools.loading.title}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ my: 1 }}>
                {children}
            </Grid>
            {action ? (
                <Grid
                    container
                    item
                    justifyContent="flex-end">
                    {action}
                </Grid>
            ) : null}
        </StyledToolRoot>
    )
}

const StyledToolRoot = styled(Grid)(({ theme }) => {
    const { spacing, palette, breakpoints } = theme
    return {
        backgroundColor: palette.background.paper,
        boxSizing: "border-box",
        marginTop: -1,
        padding: spacing(2),
        [breakpoints.up("md")]: {
            border: `1px solid ${palette.divider}`
        }
    }
})

/**
 * If the list is longer than `when` items,
 * split it into two at `first`
 */
function splitList(params: {
    links: ILinkListLink[]
    first: number
    when: number
}): [ILinkListLink[], ILinkListLink[] | null] {
    const { links, first, when } = params
    if (links.length <= when) {
        return [links, null]
    }
    return [[...links].slice(0, first), [...links].slice(first)]
}

function ToolList(props: ILinkList) {
    const { name, icon, links, more } = props
    const [expanded, setExpanded] = React.useState(false)
    const toggleExpanded = () => setExpanded(e => !e)

    const [first, rest] = splitList({ links, first: 5, when: 7 })

    const action = (
        <>
            {rest && (
                <Button
                    color="secondary"
                    onClick={toggleExpanded}
                    variant="outlined">
                    {expanded
                        ? t["tools"]["show-less"]
                        : t["tools"]["show-more"]}
                </Button>
            )}
            {more && (
                <Link
                    shallow
                    button
                    variant="outlined"
                    color="secondary"
                    href={more.href}>
                    {more.text}
                </Link>
            )}
        </>
    )

    return (
        <Tool
            action={action}
            title={name}
            icon={icon}>
            <StyledUL>
                {first.map(item => (
                    <li
                        key={item.text}
                        title={item.text}>
                        <StyledListLink
                            noWrap
                            color="secondary"
                            href={item.href}
                            target={item.target}>
                            {item.text}
                        </StyledListLink>
                    </li>
                ))}
                <Collapse in={expanded}>
                    {rest?.map(item => (
                        <li
                            key={item.text}
                            title={item.text}>
                            <Link
                                noWrap
                                color="secondary"
                                href={item.href}
                                target={item.target}>
                                {item.text}
                            </Link>
                        </li>
                    ))}
                </Collapse>
            </StyledUL>
        </Tool>
    )
}

const StyledUL = styled("ul")(() => ({
    listStyleType: "none",
    padding: 0,
    margin: 0
}))

const StyledListLink = styled(Link)(({ theme }) => {
    const { spacing } = theme
    return {
        display: "block",
        padding: spacing(0.25, 0.5),
        margin: spacing(0.25, 0)
    }
})

interface IToolsProps {
    tools?: ILinkList[]
    regionalContentId?: string
}

function Tools(props: IToolsProps) {
    const { tools, regionalContentId } = props

    if (!tools?.length) {
        return null
    }

    // REVIEW: regional-icon should be the same for all handbooks OR come from backend like other tools
    const regionalIconName =
        process.env.NEXT_PUBLIC_LOCALE === "no" ? "regional" : "room-outlined"

    return (
        <StyledToolsRoot>
            {regionalContentId ? (
                <Tool
                    icon={regionalIconName}
                    action={
                        <Link
                            color="secondary"
                            href={`#${regionalContentId}`}
                            variant="body2">
                            {t["tools"]["regional-content"]["action"]}
                        </Link>
                    }
                    title={t["tools"]["regional-content"]["title"]}>
                    <Typography
                        variant="body2"
                        gutterBottom>
                        {t["tools"]["regional-content"]["content"]}
                    </Typography>
                </Tool>
            ) : null}
            {tools.map(tool => (
                <ToolList
                    key={tool.name}
                    {...tool}
                />
            )) ?? null}
        </StyledToolsRoot>
    )
}

const StyledToolsRoot = styled("div")(({ theme }) => {
    const { spacing, breakpoints, constants } = theme
    return {
        maxWidth: constants.width.rightAside,
        "&::-webkit-scrollbar": {
            width: "4px"
        },
        "&::-webkit-scrollbar-track": {
            marginTop: "2px",
            backgroundColor: theme.palette.background?.light
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: grey[500]
        },
        overflowY: "scroll",
        height: `calc(100vh - ${
            constants.height.header + constants.height.breadcrumbs
        }px)`,
        [breakpoints.up("md")]: {
            marginBottom: spacing(4)
        }
    }
})

export default React.memo(Tools)

export function ToolsSkeleton() {
    return (
        <div>
            <Tool
                title={
                    <Skeleton
                        height={41}
                        width={174}
                    />
                }>
                <Skeleton
                    height={27}
                    width={124}
                />
                <Skeleton
                    height={27}
                    width={148}
                />
            </Tool>
            <Tool
                title={
                    <Skeleton
                        height={41}
                        width={220}
                    />
                }>
                <Skeleton
                    height={27}
                    width={124}
                />
                <Skeleton
                    height={27}
                    width={161}
                />
                <Skeleton
                    height={27}
                    width={148}
                />
            </Tool>
            <Tool
                title={
                    <Skeleton
                        height={41}
                        width={240}
                    />
                }>
                <Skeleton
                    height={27}
                    width={124}
                />
                <Skeleton
                    height={27}
                    width={161}
                />
                <Skeleton
                    height={27}
                    width={124}
                />
                <Skeleton
                    height={27}
                    width={192}
                />
                <Skeleton
                    height={27}
                    width={148}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Skeleton
                        height={48}
                        width={80}
                    />
                </div>
            </Tool>
        </div>
    )
}
