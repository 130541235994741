import Collapsible from "components/Collapsible"
import Link from "components/Link"
import { t } from "locales"
import React from "react"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { IReference } from "data/contentData/api/content"

interface IProps {
    id: string
    references: IReference[]
    template?: "Professional" | "Default"
}

export default function ArticleReferencesSection({
    id,
    references,
    template = "Default"
}: IProps) {
    if (!references) {
        return null
    }

    const _references = references.map(ref => {
        const cleanText = ref.text.replace(/[^a-zA-Z]/g, "").toLocaleLowerCase()
        const textId = cleanText.length > 3 ? cleanText.substring(0, 3) : ""
        return {
            key: `${ref.referenceId}-${textId}`,
            ...ref
        }
    })

    const title = t.article["references-title"]
    const refs = (
        <Grid
            container
            component="ol">
            {_references.map(reference => (
                <Reference
                    key={reference.key}
                    reference={reference}
                />
            ))}
        </Grid>
    )

    if (template === "Professional") {
        return (
            <Collapsible
                id={id}
                level={3}
                title={title}>
                {refs}
            </Collapsible>
        )
    }

    return (
        <>
            <Typography
                variant="h3"
                gutterBottom>
                {title}
            </Typography>
            {refs}
        </>
    )
}

interface IReferenceProps {
    reference: IReference
}

export function Reference({ reference }: IReferenceProps) {
    const { text, link } = reference

    return (
        <Grid
            item
            component="li">
            {text}
            {link && (
                <Link
                    display="block"
                    href={link.href}
                    title={link.title}
                    color="secondary">
                    {`${link.text ?? new URL(link.href).host} `}
                </Link>
            )}
        </Grid>
    )
}
