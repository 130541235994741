import Link from "components/Link"
import PortableText from "components/PortableText/PortableText"
import Title from "components/Title"
import useUser from "hooks/useUser"
import * as React from "react"

import { styled } from "@mui/material/styles"

import { IPage } from "data/contentData/api/content"
import { useGetNonSubscriberInformation } from "data/contentData/hooks/config.hooks"

function hasValidSubscription(roles: string[] | undefined) {
    return roles?.some(role => role.startsWith("Handbook."))
}

export default function NonSubscriberSection({ page }: { page: IPage }) {
    const { user, isLoading } = useUser()

    // Section should only be visible for:
    // - Pages with patient information template
    // - Not authenticated OR no valid subscription
    const enabled = !hasValidSubscription(user?.roles) && !isLoading

    const { data: section } = useGetNonSubscriberInformation({
        enabled
    })

    if (!section || isLoading) {
        return null
    }

    return (
        <StyledSection
            className="no-print"
            id="gtm-nonsubscriber-section">
            {section.title && (
                <StyledTitle
                    id={section.title}
                    level={2}>
                    {section.title}
                </StyledTitle>
            )}
            <StyledContent>
                <PortableText
                    value={section.blockContent}
                    wrapImgWithGalleryLink
                />
                {page.source && (
                    <ul>
                        <li>
                            <Link href={page.source.url}>
                                {page.source.name}
                            </Link>
                        </li>
                    </ul>
                )}
            </StyledContent>
        </StyledSection>
    )
}

const StyledSection = styled("section")(({ theme }) => ({
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.notes.guidelines.summary.hover}`
}))

const StyledTitle = styled(Title)(({ theme }) => ({
    backgroundColor: theme.palette.notes.guidelines.summary.main,
    padding: theme.spacing(2)
}))

const StyledContent = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.notes.guidelines.details
}))
