import { PortableTextReactComponents } from "@portabletext/react"
import PortableText from "components/PortableText/PortableText"
import * as React from "react"

import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

interface ISummaryProps {
    id: string
    content: any
}

export default function SummaryContent({ id, content }: ISummaryProps) {
    return (
        <Summary className="summary polyfill-summary-content">
            <SummaryId id={id} />
            <PortableText
                value={content}
                customComponents={summaryComponents}
            />
        </Summary>
    )
}

const Summary = styled("div")(({ theme }) => ({
    margin: theme.spacing(2),
    display: "grid",
    [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "125px 1fr"
    },
    "& div:nth-of-type(odd)": {
        fontWeight: 700
    },
    "& div:nth-of-type(even)": {
        marginBottom: theme.spacing(2)
    }
}))

const SummaryId = styled("span")(({ theme }) => ({
    border: "1px solid red",
    position: "absolute",
    top: -theme.constants.height.header - 16,
    [theme.breakpoints.up("md")]: {
        top:
            -theme.constants.height.header -
            theme.constants.height.breadcrumbs -
            16
    }
}))

const summaryComponents: Partial<PortableTextReactComponents> = {
    block: ({ children }) => {
        return <Typography component="div">{children}</Typography>
    }
}
