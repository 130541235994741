import Icon from "components/Icon"
import { useState } from "react"

import { styled } from "@mui/material/styles"

export default function InteractiveJSON({ json }: { json: string }) {
    const obj = JSON.parse(json)

    return (
        <InteractiveJSONWrapper>
            {Object.entries(obj).map(([key, value]) => (
                <JsonProp
                    key={key}
                    name={key}
                    value={value}
                />
            ))}
        </InteractiveJSONWrapper>
    )
}

function JsonProp({
    name,
    value,
    level = 1
}: {
    name?: string
    value?: any
    level?: number
}) {
    const [open, setOpen] = useState(false)

    if (Array.isArray(value)) {
        const values = value.map((i, index) => ({
            id: index,
            value: i
        }))

        return (
            <IndentedContainer>
                <PropName
                    name={name}
                    count={values.length}
                    open={open}
                    handleClick={() => setOpen(!open)}
                />

                {open ? (
                    <>
                        &#91;
                        <GuidelineContainer>
                            {values.map(valueOfValues => (
                                <JsonProp
                                    key={valueOfValues.id}
                                    value={valueOfValues.value}
                                    level={level}
                                />
                            ))}
                        </GuidelineContainer>
                        &#93;
                    </>
                ) : (
                    <span>&#91;...&#93;</span>
                )}
            </IndentedContainer>
        )
    }

    if (value && typeof value === "object")
        return (
            <IndentedContainer>
                <PropName
                    name={name}
                    open={open}
                    handleClick={() => setOpen(!open)}
                />
                {open ? (
                    <>
                        &#123;
                        <GuidelineContainer>
                            {Object.entries(value).map(([k, v]) => (
                                <JsonProp
                                    key={k}
                                    name={k}
                                    value={v}
                                    level={level + 1}
                                />
                            ))}
                        </GuidelineContainer>
                        &#125;,
                    </>
                ) : (
                    <span>&#123;...&#125;</span>
                )}
            </IndentedContainer>
        )

    if (typeof value === "string")
        return (
            <IndentedContainer>
                {name && <strong>{name}: </strong>}
                <span>{`"${value}"`}</span>
            </IndentedContainer>
        )

    if (typeof value === "number" || typeof value === "boolean")
        return (
            <IndentedContainer>
                {name && <strong>{name}: </strong>}
                <span>{`${value.toString()}`}</span>
            </IndentedContainer>
        )

    return (
        <IndentedContainer>
            <PropName name={name} />
            <span>{value}</span>
        </IndentedContainer>
    )
}

function PropName({
    name,
    count,
    open,
    handleClick
}: {
    name?: string
    count?: number
    open?: boolean
    handleClick?: () => void
}) {
    const displayName = name ? (
        <span>
            {name}
            {count && <span style={{ marginLeft: 2 }}>({count})</span>}:
        </span>
    ) : (
        <span />
    )

    return (
        <StyledPropName onClick={handleClick}>
            {handleClick && (
                <StyedArrowForward
                    name="arrowForwardIos"
                    rotated={open ? "true" : "false"}
                />
            )}
            {displayName}
        </StyledPropName>
    )
}

const InteractiveJSONWrapper = styled("div")(({ theme }) => ({
    marginLeft: theme.spacing(-2)
}))

const StyledPropName = styled("strong")(({ theme }) => ({
    cursor: "pointer",
    paddingRight: theme.spacing(1),
    display: "inline-flex",
    gap: theme.spacing(0.5),
    alignItems: "center"
}))

const StyedArrowForward = styled(Icon)<{ rotated: string }>(({ rotated }) => ({
    fontSize: "10px",
    transform: rotated === "true" ? "rotate(90deg)" : "initial"
}))

const IndentedContainer = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(4)
}))

const GuidelineContainer = styled("div")(({ theme }) => ({
    borderLeft: `1px dashed ${theme.palette.grey[400]}`
}))
