import React from "react"

/**
 * Checks if mouse is actively pressed down
 * @returns
 */
export default function useMousePressed() {
  const [isPressed, setIsPressed] = React.useState(false)
  const handler = React.useCallback((event: MouseEvent) => {
    setIsPressed(event.type === "mousedown")
  }, [])

  React.useEffect(() => {
    document.addEventListener("mousedown", handler)
    document.addEventListener("mouseup", handler)

    return () => {
      document.removeEventListener("mousedown", handler)
      document.removeEventListener("mouseup", handler)
    }
  }, [handler])

  return { isPressed }
}
